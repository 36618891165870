define("discourse/plugins/hosted-site/discourse/components/modal/admin-emergency-email", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title="🚑 Is it urgent?"
    @closeModal={{@closeModal}}
    class="hosted-emergency-email"
  >
    <:body>
      <div>
        Customers may use our urgent support email address for requests that meet
        one these criteria:
        <ul>
          <li>Your forum is actively undergoing a
            <strong>malicious attack</strong>
            or
            <strong>data compromise</strong>
            or is under imminent threat of either.</li>
          <li>Your forum is
            <strong>down</strong>
            or
            <strong>unresponsive</strong>
            such that users cannot read, make posts, or search your forum.</li>
        </ul>
      </div>
  
      <hr />
      <p>🚨
        <strong>Please note that urgent requests may page our staff off-hours</strong>
        🚨
      </p>
  
      <div>
        <p>If you are facing one of these issues that require immediate attention,
          please email
          <code>urgent@discourse.org</code>.</p>
  
        <p>Make sure to check our
          <a href="https://status.discourse.org/">status page</a>
          first to see if we are already aware of the issue.</p>
      </div>
    </:body>
  </DModal>
  */
  {
    "id": "0MOiUWva",
    "block": "[[[8,[39,0],[[24,0,\"hosted-emergency-email\"]],[[\"@title\",\"@closeModal\"],[\"🚑 Is it urgent?\",[30,1]]],[[\"body\"],[[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n      Customers may use our urgent support email address for requests that meet\\n      one these criteria:\\n      \"],[10,\"ul\"],[12],[1,\"\\n        \"],[10,\"li\"],[12],[1,\"Your forum is actively undergoing a\\n          \"],[10,\"strong\"],[12],[1,\"malicious attack\"],[13],[1,\"\\n          or\\n          \"],[10,\"strong\"],[12],[1,\"data compromise\"],[13],[1,\"\\n          or is under imminent threat of either.\"],[13],[1,\"\\n        \"],[10,\"li\"],[12],[1,\"Your forum is\\n          \"],[10,\"strong\"],[12],[1,\"down\"],[13],[1,\"\\n          or\\n          \"],[10,\"strong\"],[12],[1,\"unresponsive\"],[13],[1,\"\\n          such that users cannot read, make posts, or search your forum.\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"hr\"],[12],[13],[1,\"\\n    \"],[10,2],[12],[1,\"🚨\\n      \"],[10,\"strong\"],[12],[1,\"Please note that urgent requests may page our staff off-hours\"],[13],[1,\"\\n      🚨\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[12],[1,\"\\n      \"],[10,2],[12],[1,\"If you are facing one of these issues that require immediate attention,\\n        please email\\n        \"],[10,\"code\"],[12],[1,\"urgent@discourse.org\"],[13],[1,\".\"],[13],[1,\"\\n\\n      \"],[10,2],[12],[1,\"Make sure to check our\\n        \"],[10,3],[14,6,\"https://status.discourse.org/\"],[12],[1,\"status page\"],[13],[1,\"\\n        first to see if we are already aware of the issue.\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/components/modal/admin-emergency-email.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});
define("discourse/plugins/hosted-site/discourse/components/modal/admin-referral-link", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title="Give $50, Get $25!"
    @closeModal={{@closeModal}}
    class="hosted-referral-link"
  >
    <p>
      Refer someone with this unique link to get $50 off their first payment and
      earn $25 off your next payment!
    </p>
    <p>
      <TextField
        {{auto-focus selectText=true}}
        @value="https://discourse.org?r={{@model.referralCode}}"
        id="customer-referral-link"
      />
    </p>
    <p class="referral-tc">
      Offer valid midnight ET November 26, 2023 to 11:59:59 PM ET November 25,
      2024. Excludes Enterprise tier subscriptions. Existing customers can redeem
      up to $250 off from multiple referrals. One code redemption per new
      customer. Cannot be combined with other offers. No cash value. Void where
      prohibited.
    </p>
  </DModal>
  */
  {
    "id": "/stkbwqY",
    "block": "[[[8,[39,0],[[24,0,\"hosted-referral-link\"]],[[\"@title\",\"@closeModal\"],[\"Give $50, Get $25!\",[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Refer someone with this unique link to get $50 off their first payment and\\n    earn $25 off your next payment!\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    \"],[8,[39,1],[[24,1,\"customer-referral-link\"],[4,[38,2],null,[[\"selectText\"],[true]]]],[[\"@value\"],[[29,[\"https://discourse.org?r=\",[30,2,[\"referralCode\"]]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[14,0,\"referral-tc\"],[12],[1,\"\\n    Offer valid midnight ET November 26, 2023 to 11:59:59 PM ET November 25,\\n    2024. Excludes Enterprise tier subscriptions. Existing customers can redeem\\n    up to $250 off from multiple referrals. One code redemption per new\\n    customer. Cannot be combined with other offers. No cash value. Void where\\n    prohibited.\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"text-field\",\"auto-focus\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/components/modal/admin-referral-link.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});